import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const cast = (components) => createCrudRoutes("cast", "/content/cast", {
    components: components,
    roles: ['staff', 'admin'],
    listProps: {
        list: {
            headers: [
                {
                    key: 'name',
                    title: 'Name',
                    format: 'listItem',
                    titleField: 'fullName',
                    imageClass: 'rounded-circle',
                    linkOptions: {
                        routeName: 'cast.edit',
                        params: {
                            id: 'id'
                        }
                    }
                },
                {
                    key: 'titles_published',
                    title: 'Linked titles',
                    format: 'linkedResource',
                },
                { key: 'createdAt', title: 'Created At', format: 'date' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'casts',
                name: '',
                surname: '',
            },
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text', class: 'col-md-6' },
                        { key: 'surname', label: 'Surname', type: 'text', class: 'col-md-6' },
                    ]
                ]
            }
        },
        edit: {
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text', class: 'col-md-6' },
                        { key: 'surname', label: 'Surname', type: 'text', class: 'col-md-6' }
                    ]
                ]
            }
        }
    }
});

export {
    cast
}