import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const campaign = (components) => createCrudRoutes("campaign", "/marketing/campaigns", {
    components: components,
    roles: ['staff', 'admin'],
    listProps: {
        list: {
            headers: [
                { key: 'name', title: 'Name', format: 'text', fields: ['name'] },
                { key: 'vendor', title: 'Vendor', format: 'text', fields: ['vendor'] },
                { key: 'startDate', title: 'Start Date', format: 'date' },
                { key: 'endDate', title: 'End Date', format: 'date' },
                { key: 'status', title: 'Status', format: 'status' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'campaigns',
                name: '',
                vendor: '',
                description: '',
                status: 'inactive',
                startDate: '',
                endDate: '',
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                        { key: 'vendor', label: 'Vendor', type: 'text' },
                        { key: 'startDate', label: 'Start Date', type: 'date', class: 'col-md-6' },
                        { key: 'endDate', label: 'End Date', type: 'date', class: 'col-md-6' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: '', type: 'status', statusType: 'simple' },
                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                        { key: 'vendor', label: 'Vendor', type: 'text' },
                        { key: 'startDate', label: 'Start Date', type: 'date', class: 'col-md-6' },
                        { key: 'endDate', label: 'End Date', type: 'date', class: 'col-md-6' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: '', type: 'status', statusType: 'simple' },
                    ]
                ]
            }
        },
    }
});

export {
    campaign
}