// src/helpers/formatColumn.js
import moment from 'moment';
import placeholder from '/@/assets/img/placeholder.jpg';

const DATE_FORMAT = 'DD MMM YYYY, hh:mm a';
const IMAGE_SIZE = '80x80';

class FormatColumn {
    constructor() {
        this.formatters = {
            text: this.asGenericText.bind(this),
            image: this.asGenericImage.bind(this),
            link: this.asGenericLink.bind(this),
            badge: this.asGenericBadge.bind(this),
            complex: this.asGenericComplex.bind(this),
            date: this.asDate.bind(this),
            actions: this.asActions.bind(this),
            tags: this.asPills.bind(this),
            duration: this.asDurationWithChapters.bind(this),
            bold: this.asSimpleBold.bind(this),
            relation: this.asRelation.bind(this),
            inlineCode: this.asInlineCode.bind(this),
            code: this.asCode.bind(this),
            status: this.asStatus.bind(this),
            subtitle: this.asSubtitle.bind(this),
            subscribed: this.asSubscribed.bind(this),
            listItem: this.asListItem.bind(this),
            linkedResource: this.asLinkedResource.bind(this),
        };

        this.fieldAliases = {
            fullName: ['name', 'surname'],
            authors: (row) => Array.isArray(row.authors)
                ? row.authors.map(author => `${author.name || ''} ${author.surname || ''}`.trim()).join(', ')
                : '',
            cast: (row) => row.casts.map(actor => `${actor.name || ''} ${actor.surname || ''}`.trim()).join(', '),
        };
    }

    // Generic method to get value from row using aliases or direct field access
    getValueFromRow(row, field) {
        if (typeof field === 'function') return field(row);
        if (typeof field === 'string') {
            return this.fieldAliases[field] ? this.getValueFromRow(row, this.fieldAliases[field]) : row[field];
        }
        if (Array.isArray(field)) return field.map(f => row[f] || '').join(' ').trim();
        return '';
    }


    asGenericText(row, options = {}) {
        const {
            fields = ['name'],
            separator = ' ',
            wrapper = 'div',
            className = 'text-bold my-auto',
            transform = (value) => value
        } = options;

        const text = fields.map(field => row[field] || '').join(separator).trim();
        const transformedText = transform(text);
        return `<${wrapper} class="${className}">${transformedText}</${wrapper}>`;
    }

    asGenericImage(row, options = {}) {
        const {
            src = 'profile_image',
            alt = '',
            className = 'avatar-sm rounded-circle',
            fallback = placeholder
        } = options;

        const imgSrc = row[src] || fallback;
        return `<img src="${imgSrc}" class="${className}" alt="${alt}">`;
    }

    asGenericLink(row, options = {}) {
        const {
            text = row.name || row.title || row.id,
            href = '#',
            target = '_self',
            className = 'text-decoration-none text-primary',
            icon,
            isRouter = false
        } = options;

        const iconHtml = icon ? `<i class="${icon} me-1"></i>` : '';
        const linkTag = isRouter ? 'router-link' : 'a';
        const hrefAttr = isRouter ? ':to' : 'href';

        return `<${linkTag} ${hrefAttr}="${href}" target="${target}" class="${className}">
            ${iconHtml}${text}
        </${linkTag}>`;
    }

    asGenericRoute(row, options = {}) {
        const {
            label = row.name || row.title || row.id,
            routeName,
            params = {},
            query = {},
            className = 'text-decoration-none cursor-pointer router-link-action text-primary text-bold',
        } = options;

        const processValue = (value, row) => {
            if (typeof value === 'function') return value(row);
            if (typeof value === 'object' && value !== null) {
                return Object.entries(value).reduce((acc, [k, v]) => {
                    acc[k] = processValue(v, row);
                    return acc;
                }, {});
            }
            return row[value] || value;
        };

        const routeParams = processValue(params, row);
        const routeQuery = processValue(query, row);

        const routeData = {
            name: routeName,
            params: routeParams,
            query: routeQuery
        };

        return `<div data-route='${JSON.stringify(routeData)}' class="${className}">
            ${label}
        </div>`;
    }

    asGenericBadge(row, options = {}) {
        const {
            field = 'status',
            className = 'badge badge-sm me-1',
            style = 'primary',
            text = null,
            pill = false,
            transform = (value) => value
        } = options;

        const parsedText = text ? text : transform(this.getValueFromRow(row, field));
        const pillClass = pill ? 'rounded-pill' : '';
        const badgeStyle = typeof style === 'function' ? style(row) : style;
        return `<span class="${className} ${pillClass} bg-${badgeStyle.toLowerCase()}">${parsedText}</span>`;
    }

    asGenericComplex(row, options = {}) {
        const {
            components = [],
            wrapper = 'div',
            wrapperClass = 'd-flex'
        } = options;

        const content = components.map(comp => {
            switch (comp.type) {
                case 'image':
                    return this.asGenericImage(row, comp);
                case 'text':
                    return this.asGenericText(row, comp);
                case 'link':
                    return this.asGenericLink(row, comp);
                case 'badge':
                    return this.asGenericBadge(row, comp);
                default:
                    return '';
            }
        }).join('');

        return `<${wrapper} class="${wrapperClass}">${content}</${wrapper}>`;
    }

    asListItem(row, options = {}) {
        const {
            imageField = 'image',
            titleField = 'title',
            subtitleField = null,
            subtitleAsSlug = false,
            linkOptions = null,
            imageSize = '40',
            imageClass = 'rounded',
            titleClass = 'strong d-block text-dark text-bold lh-1',
            subtitleClass = 'small',
            wrapperClass = 'd-flex align-items-center',
            contentClass = 'my-auto'
        } = options;

        let imageHtml = '';
        if (imageField) {
            const imgSrc = this.getValueFromRow(row, imageField) ?
                this.getValueFromRow(row, imageField).replace('.png', `-${IMAGE_SIZE}.png`) :
                placeholder;
            imageHtml = `<img src="${imgSrc}" width="${imageSize}" class="${imageClass}" alt="">`;
        }

        let titleHtml = this.getValueFromRow(row, titleField);
        let subtitleHtml = '';

        if (subtitleField) {
            const finalSubtitleClass = `${subtitleClass} ${subtitleAsSlug ? 'pill text-xs font-monospace' : ''}`;

            subtitleHtml = `<small class="${finalSubtitleClass}">${this.getValueFromRow(row, subtitleField)}</small>`;
        }

        let contentHtml = `
            <div class="${contentClass} ${imageField ? 'ms-3' : ''}">
                <span class="${titleClass}">${titleHtml}</span>
                ${subtitleHtml}
            </div>
        `;

        let wrapperHtml = `
            <div class="${wrapperClass}">
                ${imageHtml}
                ${contentHtml}
            </div>
        `;

        if (linkOptions) {
            const { routeName, params = {}, query = {} } = linkOptions;
            const routeParams = Object.entries(params).reduce((acc, [key, value]) => {
                acc[key] = typeof value === 'function' ? value(row) : row[value] || value;
                return acc;
            }, {});
            const routeQuery = Object.entries(query).reduce((acc, [key, value]) => {
                acc[key] = typeof value === 'function' ? value(row) : row[value] || value;
                return acc;
            }, {});

            const routeData = {
                name: routeName,
                params: routeParams,
                query: routeQuery
            };

            wrapperHtml = `
                <div
                    data-route='${JSON.stringify(routeData)}'
                    class="text-decoration-none cursor-pointer router-link-action"
                >
                    ${wrapperHtml}
                </div>
            `;
        }

        return wrapperHtml;
    }

    asLinkedResource(row, options = {}) {
        const {
            key = 'name',
            linkOptions = null,
            label = 'titles'
        } = options;

        const value = this.getValueFromRow(row, key);

        if (linkOptions && value > 0) {
            return this.asGenericRoute(row, {
                label: `${value} ${label}`,
                routeName: linkOptions.routeName,
                params: linkOptions.params,
                query: linkOptions.query
            });
        }

        return `<div class="text-bold my-auto">${value > 0 ? value : 0} ${value > 0 ? label : ''}</div>`;
    }

    asDate(value, options = {}) {
        const { format = DATE_FORMAT } = options;
        return this.asGenericText(
            { formattedDate: moment(value).format(format) },
            { fields: ['formattedDate'], className: 'text-nowrap' }
        );
    }

    asActions(row, options = {}) {
        const { actions = ['edit', 'delete'] } = options;
        return actions.map(action => this.addActionButton(row.id, action)).join('');
    }

    addActionButton(id, action) {
        const icons = { edit: 'edit', delete: 'trash-alt' };
        return `
            <a id="${id}" class="actionButton ${action.toLowerCase()}Button cursor-pointer me-3" data-bs-toggle="tooltip" title="${action}">
                <i class="fas fa-${icons[action]} text-muted"></i>
            </a>`;
    }

    asDurationWithChapters(row) {
        if (!row.chapters || row.chapters.length === 0) {
            return '---';
        }

        const totalDuration = row.chapters.reduce((total, chapter) => total + (chapter.duration || 0), 0);
        const hours = Math.floor(totalDuration / 3600);
        const minutes = Math.floor((totalDuration % 3600) / 60);
        const seconds = totalDuration % 60;

        const durationText = [
            hours > 0 ? `${hours}h` : '',
            minutes > 0 ? `${minutes}m` : '',
            seconds > 0 ? `${seconds}s` : ''
        ].filter(Boolean).join(' ');

        return `<div class="d-flex align-items-center">
            <strong>${durationText}</strong>
            <span class="ms-2">(${row.chapters.length} Chapters)</span>
        </div>`;
    }

    asSimpleBold(row, options = {}) {
        return this.asGenericText(row, {
            fields: ['name', 'title'],
            className: 'text-bold my-auto'
        });
    }

    asRelation(row, options = {}) {
        return this.asSimpleBold(row[options.field || 'name']);
    }

    asInlineCode(row, options = {}) {
        return this.asGenericBadge(
            row,
            {
                field: options.field || 'value',
                className: 'badge badge-lg me-1 bg-light text-danger text-lowercase font-monospace'
            }
        );
    }

    asCode(value) {
        if (!value) return '';
        try {
            const jsonHtml = JSON.stringify(value, null, 2)
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"(.*?)":/g, '<span class="json-key">"$1"</span>:')
                .replace(/: "(.*?)"/g, ': <span class="json-string">"$1"</span>')
                .replace(/: (true|false)/g, ': <span class="json-boolean">$1</span>')
                .replace(/: (null)/g, ': <span class="json-null">$1</span>')
                .replace(/: ([0-9]+)/g, ': <span class="json-number">$1</span>');
            return `<pre class="code-view">${jsonHtml}</pre>`;
        } catch (e) {
            return '<span class="json-error">Invalid JSON</span>';
        }
    }

    asStatus(row, options = {}) {
        const { field = 'status', activeValue = true } = options;
        let status;
        if (row[field] !== undefined) {
            status = row[field];
        } else {
            status = row.is_active === activeValue ? 'Active' : 'Inactive';
        }
        return `<span class="badge badge me-1 rounded-pill status-${status.toLowerCase()}">${status}</span>`;
    }

    asSubtitle(value) {
        return this.asGenericText({ value }, { fields: ['value'] });
    }

    asSubscribed(row, options = {}) {
        const { field = 'subscribed' } = options;
        return this.asGenericBadge(
            row,
            {
                text: row[field] ? 'Subscribed' : 'No',
                style: (row) => row[field] ? 'primary' : 'light text-dark',
                className: 'badge badge-sm me-1 rounded-pill'
            }
        );
    }

    asPills(row, options = {}) {
        const { limit = 0, style = 'primary', key } = options;

        // Ensure value is an array
        const list = Array.isArray(row[key]) ? row[key] : [row[key]];
        // Extract tag names, handling both object and string cases
        const allTags = list.map(tag => typeof tag === 'object' ? (tag.name || tag.title || '') : tag);

        const visibleTags = limit > 0 ? allTags.slice(0, limit) : allTags;
        const remainingCount = Math.max(0, allTags.length - visibleTags.length);

        const pillComponents = visibleTags.map(tag => ({
            type: 'badge',
            field: 'name',
            text: tag,
            style: style,
            className: 'badge badge-sm me-1'
        }));

        if (remainingCount > 0) {
            pillComponents.push({
                type: 'badge',
                text: `+ ${remainingCount}`,
                style: 'light text-dark',
                className: 'badge badge-sm me-1',
                attributes: {
                    title: allTags.join(', ')
                }
            });
        }

        return this.asGenericComplex(list, {
            components: pillComponents,
            wrapperClass: 'd-flex flex-wrap'
        });
    }

    format(value, options) {
        const { format, ...rest } = options;
        if (this.formatters[format]) {
            return this.formatters[format](value, rest);
        }
        return value;
    }

    addFieldAlias(name, definition) {
        this.fieldAliases[name] = definition;
    }

    prepareFormattedRows(row, headers) {
        return headers.map(header => this.format(row, header));
    }
}

export default new FormatColumn();
