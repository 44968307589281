import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const author = (components) => createCrudRoutes("author", "/content/authors", {
    components: components,
    roles: ['staff', 'admin'],
    listProps: {
        list: {
            headers: [
                {
                    key: 'name',
                    title: 'Name',
                    format: 'listItem',
                    titleField: 'fullName',
                    imageClass: 'rounded-circle',
                    linkOptions: {
                        routeName: 'author.edit',
                        params: {
                            id: 'id'
                        }
                    }
                },
                {
                    key: 'titles_published',
                    title: 'Linked titles',
                    format: 'linkedResource',
                    linkOptions: {
                        routeName: 'title.list',
                        query: {
                            filter: {
                                author: 'id'
                            }
                        }
                    }
                },
                { key: 'createdAt', title: 'Created At', format: 'date' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'authors',
                name: '',
                surname: '',
                about: '',
                website: '',
            },
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text', class: 'col-md-6' },
                        { key: 'surname', label: 'Surname', type: 'text', class: 'col-md-6' },
                        { key: 'website', label: 'Website', type: 'text' },
                        { key: 'about', label: 'About', type: 'html' },
                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text', class: 'col-md-6' },
                        { key: 'surname', label: 'Surname', type: 'text', class: 'col-md-6' },
                        { key: 'website', label: 'Website', type: 'text' },
                        { key: 'about', label: 'About', type: 'html' },
                    ]
                ],
                side: [
                    [
                        { key: 'image', label: '', type: 'image', resource: 'author', uploadKey: 'id' },
                    ]
                ]
            }
        }
    }
});

export {
    author
}