import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const region = (components) => createCrudRoutes("region", "/settings/regions", {
    components: components,
    roles: ['admin'],
    listProps: {
        list: {
            sortDirection: 'id',
            headers: [
                { key: 'country', title: 'Country', format: 'text', fields: ['country'] },
                { key: 'country_code', title: 'Country Code', format: 'text', fields: ['country_code'] },
                { key: 'currency', title: 'Currency', format: 'text', fields: ['currency'] },
                { key: 'currency_code', title: 'Currency Code', format: 'text', fields: ['currency_code'] },
                { key: 'status', title: 'Status', format: 'status' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'regions',
                country: null,
                country_code: null,
                currency: null,
                currency_code: null,
                status: 'inactive',
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'country', label: 'Country', type: 'text', class: 'col-md-6' },
                        { key: 'country_code', label: 'Country Code', type: 'text', class: 'col-md-6' },
                        { key: 'currency', label: 'Currency', type: 'text', class: 'col-md-6' },
                        { key: 'currency_code', label: 'Currency Code', type: 'text', class: 'col-md-6' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: 'Status', type: 'status', statusType: 'simple' },
                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'country', label: 'Country', type: 'text', class: 'col-md-6' },
                        { key: 'country_code', label: 'Country Code', type: 'text', class: 'col-md-6' },
                        { key: 'currency', label: 'Currency', type: 'text', class: 'col-md-6' },
                        { key: 'currency_code', label: 'Currency Code', type: 'text', class: 'col-md-6' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: 'Status', type: 'status', statusType: 'simple' },
                    ]
                ]
            }
        }
    }
});

export {
    region
}