import { DataTable } from "simple-datatables";
import { getResourceList } from "/@/helpers";
import store from "/@/store";
import { useRoute, useRouter } from 'vue-router';

export default {
    data() {
        return {
            dataTable: null,
            pagination: {},
            searchQuery: "",
            sortBy: null,
            currentPage: 1,
            perPage: 25,
            additionalFilters: {},
            initialFilters: {},
            route: useRoute(),
            router: useRouter(),
        };
    },
    computed: {
        getSort() {
            return this.sortBy ?? this.sortDirection;
        }
    },
    methods: {
        async initializeDataTable(refName, options) {
            if (this.$refs[refName]) {
                this.parseQueryParams();

                this.dataTable = new DataTable(this.$refs[refName], {
                    ...options,
                    searchable: false,
                    fixedHeight: false,
                    perPage: this.perPage,
                });

                const dataTableBottom = document.querySelector(".dataTable-bottom");
                if (dataTableBottom) {
                    dataTableBottom.remove();
                }

                const dataTableTop = document.querySelector(".dataTable-top");
                if (dataTableTop) {
                    dataTableTop.remove();
                }

                this.dataTable.label = null;
                this.dataTable.setMessage("Loading");

                await this.fetchData();
                this.setupDataTableEvents();
            }
        },

        parseQueryParams() {
            const query = this.route.query;
            this.currentPage = parseInt(query.page) || 1;
            this.perPage = parseInt(query.perPage) || this.perPage;
            this.searchQuery = query.search || "";
            this.sortBy = query.sort || null;

            if (typeof query.filter === 'string') {
                this.additionalFilters = JSON.parse(query.filter || '{}');
            } else {
                this.additionalFilters = query.filter || {};
            }
            // console.log(this.additionalFilters);
            // Populate initialFilters
            this.initialFilters = {
                ...{
                    filter: this.additionalFilters,
                },
                search: this.searchQuery,
                sort: this.sortBy,
                perPage: this.perPage,
            };
        },

        updateQueryParams() {
            const query = {
                page: this.currentPage,
                perPage: this.perPage,
                search: this.searchQuery || undefined,
                sort: this.sortBy || undefined,
                filter: JSON.stringify(this.additionalFilters),
            };

            // Remove undefined values
            Object.keys(query).forEach(key => query[key] === undefined && delete query[key]);

            this.router.push({ query });
        },

        async fetchData() {
            await getResourceList(`${this.storeNamespace}/getList`, {
                sort: this.getSort,
                query: this.searchQuery,
                nr: this.currentPage,
                perpage: this.perPage,
                other: this.additionalFilters
            });
            this.updateQueryParams();
        },

        setupDataTableEvents() {
            this.dataTable.on("datatable.perpage", async (perPage) => {
                this.dataTable.setMessage("Loading");
                await store.dispatch(`${this.storeNamespace}/setPageSize`, perPage);
                this.perPage = perPage;
                this.currentPage = 1; // Reset to first page when changing page size
                await this.fetchData();
            });

            this.dataTable.on("datatable.sort", async (column, direction) => {
                let colName = this.dataTable.headings[column].title;
                direction = direction === "asc" ? "" : "-";
                const sortDirection = direction + colName;
                this.sortBy = sortDirection;
                await store.dispatch(`${this.storeNamespace}/setSort`, sortDirection);
                await this.fetchData();
            });

            this.dataTable.on("datatable.page", async (page) => {
                this.currentPage = page;
                await this.fetchData();
            });
        },

        async refreshTable() {
            await this.fetchData();
            this.dataTable.refresh();
            this.dataTable.insert({ data: this.dataItems });
        },

        async reactiveSearch() {
            this.searchQuery = this.search;
            await this.fetchData();
        },

        async getDataFromPage(page) {
            this.currentPage = page;
            await this.fetchData();
        },

        async reactivePagination() {
            this.pagination = await this.metaPage;
            this.pagination = {
                currentPage: this.pagination.currentPage,
                perPage: this.pagination.perPage,
                total: this.pagination.total,
            };
            console.log(this.pagination);
            this.currentPage = this.pagination.currentPage;
            this.updateQueryParams();
            return this.pagination;
        },
    },
    watch: {
        search: {
            handler: "reactiveSearch",
        },
    },
};
