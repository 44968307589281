import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const title = (components) => createCrudRoutes("title", "/content/titles", {
    components: components,
    roles: ['staff', 'admin'],
    listProps: {
        list: {
            filters: [
                {
                    type: 'search',
                    value: '',
                    name: 'title'
                },
                {
                    type: 'buttonGroup',
                    options: [
                        'Published',
                        'Draft',
                        'Archived'
                    ],
                    value: '',
                    name: 'status'
                },
                {
                    type: 'toggle',
                    value: false,
                    label: 'Free',
                    name: 'is_adsupported'
                },
                {
                    type: 'toggle',
                    value: false,
                    label: 'Subscribe Only',
                    name: 'is_subscription'
                },
                {
                    type: 'toggle',
                    value: false,
                    label: 'Purchase Only',
                    name: 'is_purchasable'
                },
            ],
            headers: [
                {
                    key: 'title',
                    title: 'Title',
                    format: 'listItem',
                    subtitleField: 'authors',
                    linkOptions: {
                        routeName: 'title.edit',
                        params: {
                            id: 'id'
                        }
                    }
                },
                {
                    key: 'chapters',
                    title: 'Duration',
                    format: 'duration'
                },
                {
                    key: 'categories',
                    title: 'Categories',
                    format: 'tags',
                    style: 'secondary'
                },
                {
                    key: 'createdAt',
                    title: 'Created At',
                    format: 'date'
                },
                {
                    key: 'status',
                    title: 'Status',
                    format: 'status'
                },
                {
                    key: 'action',
                    title: 'Action',
                    format: 'actions',
                    sortable: false
                }
            ],
        },
        add: {
            model: {
                type: 'titles',
                title: '',
                subtitle: '',
                cast: '',
                content: '',
                status: 'draft',
                is_adsupported: false,
                is_subscription: false,
                is_purchasable: false,
                subscription_plan_id: 0,
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'title', label: 'Title', type: 'text', class: 'col-md-8' },
                        { key: 'contentType', label: 'Content Type', type: 'select-dynamic', source: 'contentType', sourceType: 'content-types', class: 'col-md-4', multiple: false },

                        { key: 'subtitle', label: 'Subtitle', type: 'text' },
                        { key: 'authors', label: 'Authors', type: 'select-dynamic', source: 'author', sourceType: 'authors', class: 'col-md-4', multiple: true },
                        { key: 'casts', label: 'Cast', type: 'select-dynamic', source: 'cast', sourceType: 'casts', class: 'col-md-4', multiple: true },
                        { key: 'isbn', label: 'ISBN/EAN', type: 'text', class: 'col-md-6' },
                        { key: 'imprint', label: 'Imprint', type: 'text', class: 'col-md-6' },
                        { key: 'content', label: 'Description', type: 'html' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: '', type: 'status', statusType: 'default' },
                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'title', label: 'Title', type: 'text', class: 'col-md-8' },
                        { key: 'content-type', label: 'Content Type', type: 'select-dynamic', source: 'contentType', sourceType: 'content-types', class: 'col-md-4', multiple: false },

                        { key: 'subtitle', label: 'Subtitle', type: 'text', class: 'col-md-6' },
                        { key: 'series', label: 'Series', type: 'select-dynamic', source: 'series', sourceType: 'series', class: 'col-md-4', multiple: false },
                        { key: 'series_no', label: 'Series Number', type: 'number', class: 'col-md-2' },

                        { key: 'authors', label: 'Authors', type: 'select-dynamic', source: 'author', sourceType: 'authors', class: 'col-md-4', multiple: true },
                        { key: 'casts', label: 'Cast', type: 'select-dynamic', source: 'cast', sourceType: 'casts', class: 'col-md-4', multiple: true },
                        { key: 'rating', label: 'Age Rating', type: 'select-dynamic', source: 'rating', sourceType: 'ratings', class: 'col-md-4', multiple: false },
                        { key: 'isbn', label: 'ISBN/EAN', type: 'text', class: 'col-md-6' },
                        { key: 'imprint', label: 'Imprint', type: 'text', class: 'col-md-6' },
                        { key: 'content', label: 'Description', type: 'html' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: '', type: 'status', statusType: 'default' },
                        { key: 'publishedAt', label: 'Published Date', format: 'date', type: 'date' },
                    ],
                    [
                        { key: 'image', label: '', type: 'image', resource: 'channel', uploadKey: 'id' },
                    ],
                    [
                        { key: 'channel', label: 'Channel', type: 'select-dynamic', source: 'channel', sourceType: 'channels', multiple: false },
                        { key: 'categories', label: 'Genres', type: 'select-dynamic', source: 'category', sourceType: 'categories', multiple: true },
                        { key: 'tags', label: 'Tags', type: 'select-dynamic', source: 'tag', sourceType: 'tags', multiple: true },
                    ]
                ]
            }
        }
    }
});

export {
    title
}