import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const contentType = (components) => createCrudRoutes("contentType", "/content/content-types", {
    components: components,
    roles: ['admin'],
    listProps: {
        list: {
            sortDirection: '-id',
            headers: [
                {
                    key: 'name',
                    title: 'Name',
                    imageField: null,
                    format: 'listItem',
                    titleField: 'name',
                    linkOptions: {
                        routeName: 'contentType.edit',
                        params: {
                            id: 'id'
                        }
                    }
                },
                {
                    key: 'titles_published',
                    title: 'Linked titles',
                    format: 'linkedResource',
                },
                { key: 'createdAt', title: 'Created At', format: 'date' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'content-types',
                name: '',
            },
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                    ]
                ],
            }
        },
        edit: {
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                    ]
                ],
            }
        }
    }
});

export {
    contentType
}