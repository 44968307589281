import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const transaction = (components) => createCrudRoutes("transaction", "/sales/transactions", {
    components: components,
    roles: ['staff', 'admin'],
    cruds: ["list"],
    listProps: {
        list: {
            showAddButton: false,
            filters: [
                {
                    type: 'search',
                    value: '',
                    name: 'title'
                },
                {
                    type: 'buttonGroup',
                    options: [
                        'Created',
                        'Pending',
                        'Success',
                        'Failed',
                        'Cancelled',
                    ],
                    value: '',
                    name: 'status'
                },
                {
                    type: 'buttonGroup',
                    options: [
                        'Subscription',
                        'Purchase',
                    ],
                    value: '',
                    name: 'transactionType'
                },
            ],
            sortDirection: '-initiated',
            headers: [
                { key: 'id', title: 'ID', format: 'text', fields: ['id'] },
                { key: 'customerId', title: 'Customer', format: 'text', fields: ['customerId'] },
                { key: 'transactionType', title: 'Type', format: 'inlineCode', field: 'transactionType' },
                { key: 'paymentMethod', title: 'Payment Method', format: 'text', fields: ['paymentMethod'] },
                { key: 'currency', title: 'Currency', format: 'text', fields: ['currency'] },
                { key: 'amount', title: 'Amount', format: 'text', fields: ['amount'] },
                { key: 'status', title: 'Status', format: 'status' },
                { key: 'reference', title: 'Reference', format: 'text', fields: ['reference'] },
                { key: 'initiated', title: 'Initiated', format: 'date' },
                { key: 'completed', title: 'completed', format: 'date' },
                // { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
    }
});

export {
    transaction
}