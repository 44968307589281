import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const appTemplate = (components) => createCrudRoutes("appTemplate", "/app-templates", {
    components: components,
    roles: ['admin'],
    listProps: {
        list: {
            sortDirection: '-id',
            headers: [
                { key: 'name', title: 'Name', format: 'bold' },
                { key: 'notes', title: 'Notes', format: 'string' },
                { key: 'createdAt', title: 'Created At', format: 'date' },
                { key: 'status', title: 'Status', format: 'string' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'app-templates',
                name: '',
            },
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                    ]
                ],
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                        { key: 'notes', label: 'Notes', type: 'text' },
                    ],
                    [
                        { key: 'data', label: 'Data', type: 'layout-builder' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: 'Status', type: 'status' },
                    ]
                ]
            }
        }
    }
});

export {
    appTemplate
}