import { createCrudRoutes } from "/@/helpers/createCrudRoutes";
import admin from "/@/middleware/admin";
import auth from "/@/middleware/auth";

const app = (components) => createCrudRoutes("app", "/apps", {
    middleware: [auth, admin],
    roles: ['admin'],
    components: components,
    listProps: {
        list: {
            headers: [
                { key: 'id', title: 'ID', format: 'text', fields: ['id'] },
                { key: 'name', title: 'Name', format: 'text', fields: ['name'] },
                { key: 'status', title: 'Status', format: 'status' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'apps',
                name: '',
                config: '{}',
                status: 'inactive'
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                        { key: 'config', label: 'Config', type: 'codemirror' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: 'Status', type: 'status' },
                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                    ],
                    [
                        { key: 'config', label: 'Config', type: 'codemirror' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: 'Status', type: 'status' },
                    ]
                ]
            }
        }
    }
});

export {
    app
}
