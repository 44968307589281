import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const appSetting = (components) => createCrudRoutes("appSetting", "/settings/app-settings", {
    components: components,
    roles: ['admin'],
    listProps: {
        list: {
            headers: [
                { key: 'key', title: 'Key', format: 'inlineCode', field: 'key' },
                { key: 'value', title: 'Value', format: 'code' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'app-settings',
                key: '',
                value: '{}',
            },
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'key', label: 'Key', type: 'text' },
                        { key: 'value', label: 'Value', type: 'codemirror' },
                    ]
                ]
            }
        },
        edit: {
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'key', label: 'Key', type: 'text' },
                        { key: 'value', label: 'Value', type: 'codemirror' },
                    ]
                ]
            }
        }
    }
});

export {
    appSetting
}